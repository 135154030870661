import React, { useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import { Button } from './ui/button';
import { Avatar, AvatarFallback } from './ui/avatar';
import { useUser } from '@clerk/clerk-react';
import { supabase } from '../lib/supabase';
import { useToast } from './ui/use-toast';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from './ui/table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Copy } from 'lucide-react';

const getGradient = (str) => {
  const gradients = [
    'from-indigo-500 via-purple-500 to-pink-500',
    'from-rose-400 via-fuchsia-500 to-indigo-500',
    'from-blue-500 via-teal-500 to-emerald-500',
    'from-yellow-400 via-orange-500 to-pink-500',
    'from-green-400 via-cyan-500 to-blue-500',
    'from-purple-500 via-violet-500 to-indigo-500'
  ];
  
  const index = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % gradients.length;
  return gradients[index];
};

const Settings = () => {
  const { user } = useUser();
  const [selectedTab, setSelectedTab] = useState('members');
  const [isAddMemberOpen, setIsAddMemberOpen] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState('');
  const [newMemberRole, setNewMemberRole] = useState('member');
  const { toast } = useToast();

  const handleInviteMember = async () => {
    if (!newMemberEmail) return;

    try {
      // TODO: Implement actual invite functionality with Supabase
      console.log('Inviting member:', { email: newMemberEmail, role: newMemberRole });
      
      toast({
        title: "Invitation sent",
        description: `An invitation has been sent to ${newMemberEmail}`,
      });
      
      setIsAddMemberOpen(false);
      setNewMemberEmail('');
      setNewMemberRole('member');
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send invitation",
        variant: "destructive",
      });
    }
  };

  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email);
    toast({
      title: "Email copied",
      description: "Email address has been copied to clipboard",
    });
  };

  return (
    <div className="-mt-[15px]">
      <div className="mb-2">
        <h1 className="text-2xl font-bold tracking-tight">Settings</h1>
        <p className="text-sm text-muted-foreground">
          Configure your account and application preferences
        </p>
      </div>

      <Tabs value={selectedTab} onValueChange={setSelectedTab}>
        <TabsList>
          <TabsTrigger value="members">Members</TabsTrigger>
          <TabsTrigger value="general">General</TabsTrigger>
          <TabsTrigger value="billing">Billing</TabsTrigger>
        </TabsList>

        <TabsContent value="members" className="space-y-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-semibold">Project Members</h2>
            <Button onClick={() => setIsAddMemberOpen(true)}>Add new member</Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Email</TableHead>
                <TableHead>Organization Role</TableHead>
                <TableHead>Project Role</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <Avatar className="h-8 w-8">
                      <AvatarFallback className={`bg-gradient-to-br ${getGradient(user?.emailAddresses?.[0]?.emailAddress)} text-white`}>
                        {user?.firstName?.[0] || user?.emailAddresses?.[0]?.emailAddress?.[0]}
                      </AvatarFallback>
                    </Avatar>
                    <span>{user?.firstName} {user?.lastName}</span>
                  </div>
                </TableCell>
                <TableCell className="flex items-center gap-2">
                  {user?.emailAddresses?.[0]?.emailAddress}
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4"
                    onClick={() => handleCopyEmail(user?.emailAddresses?.[0]?.emailAddress)}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </TableCell>
                <TableCell>
                  <Select defaultValue="owner">
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="owner">Owner</SelectItem>
                      <SelectItem value="admin">Admin</SelectItem>
                      <SelectItem value="member">Member</SelectItem>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>N/A on plan</TableCell>
                <TableCell>
                  <Button variant="ghost" size="sm">
                    <Copy className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TabsContent>

        <TabsContent value="general">
          {/* General settings content */}
        </TabsContent>

        <TabsContent value="billing">
          {/* Billing settings content */}
        </TabsContent>
      </Tabs>

      <Dialog open={isAddMemberOpen} onOpenChange={setIsAddMemberOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-xl">Add new member to the organization</DialogTitle>
          </DialogHeader>
          <div className="space-y-6 pt-4">
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={newMemberEmail}
                onChange={(e) => setNewMemberEmail(e.target.value)}
                placeholder="jsdoe@example.com"
                className="bg-background"
              />
            </div>
            <div className="space-y-2">
              <Label>Organization Role</Label>
              <Select value={newMemberRole} onValueChange={setNewMemberRole}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="member">Member</SelectItem>
                  <SelectItem value="admin">Admin</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Button 
              className="w-full" 
              onClick={handleInviteMember}
              disabled={!newMemberEmail}
            >
              Grant access
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Settings; 