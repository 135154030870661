import React, { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Badge } from './ui/badge';
import { ScrollArea } from './ui/scroll-area';
import { Tabs, TabsList, TabsTrigger, TabsContent } from './ui/tabs';
import { 
  Search,
  Filter,
  Clock,
  MessageSquare,
  Bot,
  ChevronLeft,
  ChevronRight,
  Settings2,
  Plus,
  Database,
  Activity,
  BarChart2,
  Info,
  Tags,
  Copy,
  Share2,
  ExternalLink,
  Loader2
} from 'lucide-react';
import { cn } from '../lib/utils';
import { supabase } from '../lib/supabase';

const TraceView = ({ trace, onBack }) => {
  const [selectedTab, setSelectedTab] = useState('run');
  const parsedInput = React.useMemo(() => {
    try {
      return JSON.parse(trace.input);
    } catch (e) {
      return trace.input;
    }
  }, [trace.input]);

  const formatInput = (input) => {
    if (Array.isArray(input)) {
      return input.map(msg => `${msg.role}: ${msg.content}`).join('\n\n');
    }
    return input;
  };

  return (
    <div className="flex h-[calc(100vh-4rem)]">
      {/* Left Panel */}
      <div className="w-80 border-r bg-muted/10 flex flex-col">
        {/* Header */}
        <div className="p-2 border-b bg-background">
          <Button 
            variant="ghost" 
            size="sm"
            onClick={onBack}
            className="gap-2 -ml-2"
          >
            <ChevronLeft className="h-4 w-4" />
            Back
          </Button>
        </div>

        {/* Trace Flow */}
        <ScrollArea className="flex-1">
          <div className="p-4 space-y-4">
            <div className="flex items-start gap-2">
              <div className="w-6 h-6 rounded bg-primary/10 flex items-center justify-center mt-1">
                <img src="/favicon.ico" alt="Playground" className="h-4 w-4" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <span className="font-medium text-sm">Playground</span>
                  <Badge variant="success" className="text-xs">Success</Badge>
                </div>
                <div className="text-xs text-muted-foreground mt-1">
                  {(trace.latency || 0).toFixed(2)}s • {trace.total_tokens || 0} tokens
                </div>
              </div>
            </div>

            <div className="ml-3 border-l-2 border-muted pl-4 space-y-4">
              <div className="flex items-start gap-2">
                <div className="w-6 h-6 rounded bg-[#FEF6EE] flex items-center justify-center">
                  <img src="/openai-logomark.svg" alt="OpenAI" className="h-4 w-4" />
                </div>
                <div>
                  <span className="text-sm font-medium">ChatOpenAI</span>
                  <div className="flex items-center gap-1 mt-1">
                    <Badge variant="outline" className="text-xs">gpt-4o</Badge>
                    <span className="text-xs text-muted-foreground">{(trace.latency || 0).toFixed(2)}s</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-auto">
        <div className="container py-6">
          <div className="space-y-6">
            {/* Header */}
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded bg-primary/10 flex items-center justify-center">
                  <img src="/favicon.ico" alt="Playground" className="h-4 w-4" />
                </div>
                <div>
                  <h1 className="text-xl font-semibold">Playground</h1>
                  <div className="flex items-center gap-2 text-sm text-muted-foreground">
                    <Badge variant="outline">{trace.type || 'Sequence'}</Badge>
                    <span>•</span>
                    <span>{trace.project_id}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button variant="outline" size="sm" className="gap-2">
                  <Share2 className="h-4 w-4" />
                  Share
                </Button>
                <Button variant="outline" size="sm" className="gap-2">
                  <ExternalLink className="h-4 w-4" />
                  Add to
                </Button>
              </div>
            </div>

            {/* Rest of the content */}
            <Tabs value={selectedTab} onValueChange={setSelectedTab}>
              <TabsList>
                <TabsTrigger value="run">Run</TabsTrigger>
                <TabsTrigger value="feedback">Feedback</TabsTrigger>
                <TabsTrigger value="metadata">Metadata</TabsTrigger>
              </TabsList>

              <TabsContent value="run" className="space-y-4">
                {/* Input */}
                <Card>
                  <CardContent className="p-4 space-y-2">
                    <h3 className="font-medium">Input</h3>
                    <div className="text-sm rounded-lg bg-muted p-4 whitespace-pre-wrap font-mono">
                      {formatInput(parsedInput)}
                    </div>
                  </CardContent>
                </Card>

                {/* Output */}
                <Card>
                  <CardContent className="p-4 space-y-2">
                    <h3 className="font-medium">Output</h3>
                    {trace.error ? (
                      <div className="text-sm rounded-lg bg-destructive/10 text-destructive p-4 whitespace-pre-wrap font-mono">
                        {trace.error}
                      </div>
                    ) : (
                      <div className="text-sm rounded-lg bg-muted p-4 whitespace-pre-wrap font-mono">
                        {trace.output}
                      </div>
                    )}
                  </CardContent>
                </Card>

                {/* Metadata */}
                <div className="grid grid-cols-4 gap-4">
                  <Card>
                    <CardContent className="p-4">
                      <div className="flex items-center gap-2">
                        <Clock className="h-4 w-4 text-muted-foreground" />
                        <div>
                          <p className="text-sm font-medium">Latency</p>
                          <p className="text-2xl font-bold">{(trace.latency || 0).toFixed(2)}s</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-4">
                      <div className="flex items-center gap-2">
                        <MessageSquare className="h-4 w-4 text-muted-foreground" />
                        <div>
                          <p className="text-sm font-medium">Total Tokens</p>
                          <p className="text-2xl font-bold">{trace.total_tokens || 0}</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-4">
                      <div className="flex items-center gap-2">
                        <Database className="h-4 w-4 text-muted-foreground" />
                        <div>
                          <p className="text-sm font-medium">Cost</p>
                          <p className="text-2xl font-bold">${(trace.cost || 0).toFixed(6)}</p>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent className="p-4">
                      <div className="flex items-center gap-2">
                        <Activity className="h-4 w-4 text-muted-foreground" />
                        <div>
                          <p className="text-sm font-medium">Status</p>
                          <Badge variant={trace.error ? "destructive" : "success"} className="mt-1">
                            {trace.error ? "Error" : "Success"}
                          </Badge>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </TabsContent>

              <TabsContent value="feedback">
                <Card>
                  <CardContent className="p-4">
                    <p className="text-sm text-muted-foreground">No feedback available yet.</p>
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="metadata">
                <Card>
                  <CardContent className="p-4">
                    <div className="space-y-4">
                      <div>
                        <h3 className="text-sm font-medium mb-1">Run ID</h3>
                        <div className="flex items-center gap-2">
                          <code className="text-sm bg-muted rounded px-2 py-1">{trace.id}</code>
                          <Button variant="ghost" size="icon" className="h-6 w-6">
                            <Copy className="h-3 w-3" />
                          </Button>
                        </div>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium mb-1">Start Time</h3>
                        <p className="text-sm text-muted-foreground">{new Date(trace.start_time).toLocaleString()}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium mb-1">End Time</h3>
                        <p className="text-sm text-muted-foreground">{new Date(trace.end_time).toLocaleString()}</p>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium mb-1">Model</h3>
                        <p className="text-sm text-muted-foreground">{trace.model}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

const TracingTable = ({ traces, onTraceSelect }) => {
  return (
    <div className="rounded-lg border bg-card">
      <div className="grid grid-cols-[2fr,1fr,1fr,1fr,1fr,1fr] gap-4 p-4 border-b text-sm font-medium">
        <div>Name</div>
        <div>Run Count (7D)</div>
        <div>Error Rate (7D)</div>
        <div>P50 Latency (7D)</div>
        <div>P99 Latency (7D)</div>
        <div>Total Cost (7D)</div>
      </div>

      <ScrollArea className="h-[calc(100vh-20rem)]">
        {traces.map((trace) => (
          <div
            key={trace.id}
            className="grid grid-cols-[2fr,1fr,1fr,1fr,1fr,1fr] gap-4 p-4 border-b hover:bg-accent/50 cursor-pointer"
            onClick={() => onTraceSelect(trace)}
          >
            <div className="font-medium">{trace.name}</div>
            <div className="text-muted-foreground">{trace.run_count}</div>
            <div className={cn(
              trace.error_rate > 50 ? "text-destructive" : 
              trace.error_rate > 20 ? "text-yellow-500" :
              "text-muted-foreground"
            )}>
              {trace.error_rate}%
            </div>
            <div className="text-muted-foreground">{trace.p50_latency}s</div>
            <div className="text-muted-foreground">{trace.p99_latency}s</div>
            <div className="text-muted-foreground">${trace.total_cost.toFixed(6)}</div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

const SequenceView = ({ projectId, traces, onBack, onTraceSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const projectTraces = traces.filter(t => t.project_id === projectId);
  const latestTrace = projectTraces[0];

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Button variant="ghost" onClick={onBack} className="gap-2 -ml-4">
            <ChevronLeft className="h-4 w-4" />
            Back
          </Button>
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 rounded bg-primary/10 flex items-center justify-center">
              <Bot className="h-4 w-4 text-primary" />
            </div>
            <div>
              <h1 className="text-xl font-semibold">Playground</h1>
              <div className="flex items-center gap-2 text-sm text-muted-foreground">
                <Badge variant="outline">{latestTrace.type || 'Sequence'}</Badge>
                <span>•</span>
                <span>{projectId}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Search */}
      <div className="flex items-center gap-4">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input 
            placeholder="Search runs..."
            className="pl-9"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>

      {/* Traces Table */}
      <Card>
        <CardContent className="p-0">
          <div className="grid grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr] gap-4 p-4 border-b text-sm font-medium">
            <div>Name</div>
            <div>Timestamp</div>
            <div>Status</div>
            <div>Latency</div>
            <div>Tokens</div>
            <div>Cost</div>
          </div>
          <ScrollArea className="h-[calc(100vh-20rem)]">
            {projectTraces
              .filter(trace => 
                trace.input?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                trace.output?.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((trace) => (
                <div
                  key={trace.id}
                  className="grid grid-cols-[1fr,1fr,1fr,1fr,1fr,1fr] gap-4 p-4 border-b hover:bg-accent/50 cursor-pointer"
                  onClick={() => onTraceSelect(trace)}
                >
                  <div className="font-medium">
                    RunnableSequence
                  </div>
                  <div className="font-medium">
                    {new Date(trace.start_time).toLocaleString()}
                  </div>
                  <div>
                    <Badge variant={trace.error ? "destructive" : "success"}>
                      {trace.error ? "Error" : "Success"}
                    </Badge>
                  </div>
                  <div className="text-muted-foreground">
                    {(trace.latency || 0).toFixed(2)}s
                  </div>
                  <div className="text-muted-foreground">
                    {trace.total_tokens || 0}
                  </div>
                  <div className="text-muted-foreground">
                    ${(trace.cost || 0).toFixed(6)}
                  </div>
                </div>
              ))}
          </ScrollArea>
        </CardContent>
      </Card>
    </div>
  );
};

const Tracing = () => {
  const { user } = useUser();
  const [rawTraces, setRawTraces] = useState([]);
  const [traces, setTraces] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTrace, setSelectedTrace] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchTraces = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('traces')
        .select('*')
        .eq('user_id', user.id)
        .order('start_time', { ascending: false });

      if (error) throw error;

      setRawTraces(data);
      // Process traces to get aggregated stats
      const processedTraces = processTraces(data);
      setTraces(processedTraces);
    } catch (error) {
      console.error('Error fetching traces:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const processTraces = (rawTraces) => {
    // Group traces by project_id
    const groupedTraces = rawTraces.reduce((acc, trace) => {
      if (!acc[trace.project_id]) {
        acc[trace.project_id] = [];
      }
      acc[trace.project_id].push(trace);
      return acc;
    }, {});

    // Calculate stats for each project
    return Object.entries(groupedTraces).map(([project_id, projectTraces]) => {
      const last7Days = projectTraces.filter(t => 
        new Date(t.start_time) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      );

      const latencies = last7Days.map(t => t.latency).sort((a, b) => a - b);
      const p50Index = Math.floor(latencies.length * 0.5);
      const p99Index = Math.floor(latencies.length * 0.99);

      return {
        id: project_id,
        name: project_id === 'playground' ? 'Playground' : projectTraces[0].name,
        run_count: last7Days.length,
        error_rate: (last7Days.filter(t => t.error).length / last7Days.length * 100) || 0,
        p50_latency: latencies[p50Index] || 0,
        p99_latency: latencies[p99Index] || 0,
        total_cost: last7Days.reduce((sum, t) => sum + (t.cost || 0), 0)
      };
    });
  };

  const handleProjectSelect = (aggregatedTrace) => {
    setSelectedProject(aggregatedTrace.id);
  };

  const handleTraceSelect = (trace) => {
    setSelectedTrace(trace);
  };

  useEffect(() => {
    if (user) {
      fetchTraces();
    }
  }, [user]);

  if (selectedTrace) {
    return (
      <TraceView 
        trace={selectedTrace} 
        onBack={() => setSelectedTrace(null)} 
      />
    );
  }

  if (selectedProject) {
    return (
      <SequenceView 
        projectId={selectedProject}
        traces={rawTraces}
        onBack={() => setSelectedProject(null)}
        onTraceSelect={handleTraceSelect}
      />
    );
  }

  return (
    <div className="-mt-[15px]">
      <div className="mb-2">
        <h1 className="text-2xl font-bold tracking-tight">Tracing</h1>
        <p className="text-sm text-muted-foreground">
          Monitor and analyze your model's execution traces
        </p>
      </div>

      {/* Search and Filters */}
      <div className="flex items-center gap-4">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input 
            placeholder="Search by name..."
            className="pl-9"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Button variant="outline" className="gap-2">
          <Filter className="h-4 w-4" />
          Columns
        </Button>
      </div>

      {/* Traces Table */}
      {isLoading ? (
        <div className="flex items-center justify-center h-[calc(100vh-20rem)]">
          <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
        </div>
      ) : (
        <TracingTable 
          traces={traces.filter(t => 
            t.name.toLowerCase().includes(searchQuery.toLowerCase())
          )} 
          onTraceSelect={handleProjectSelect} 
        />
      )}
    </div>
  );
};

export default Tracing; 