import React, { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Button } from './ui/button';
import { Copy, Check } from 'lucide-react';
import { Input } from './ui/input';

const CodeBlock = ({ code, language, showLineNumbers = true }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const lines = code.split('\n');

  return (
    <div className="relative rounded-lg bg-muted overflow-hidden border">
      <div className="absolute right-2 top-2">
        <Button 
          variant="ghost" 
          size="icon"
          onClick={copyToClipboard}
          className="hover:bg-background/80 h-8 w-8"
        >
          {copied ? (
            <Check className="h-4 w-4 text-green-500" />
          ) : (
            <Copy className="h-4 w-4" />
          )}
        </Button>
      </div>
      <pre className="p-4 overflow-x-auto">
        {lines.map((line, i) => (
          <div key={i} className="flex">
            {showLineNumbers && (
              <span className="select-none mr-4 text-muted-foreground w-6 text-right">
                {i + 1}
              </span>
            )}
            <code className="flex-1">
              <span className="text-primary">{line}</span>
            </code>
          </div>
        ))}
      </pre>
    </div>
  );
};

const generateRandomHex = (length) => {
  const characters = '0123456789abcdef';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    console.log('Random index:', randomIndex, 'Character:', characters[randomIndex]);
    result += characters[randomIndex];
  }
  return result;
};

const SetupTracing = () => {
  const [projectName, setProjectName] = useState('pr-silver-worth-12');
  const [apiKey, setApiKey] = useState('');

  const generateKey = () => {
    const firstPart = generateRandomHex(28);
    const secondPart = generateRandomHex(10);
    const newKey = `lsv2_pt_${firstPart}_${secondPart}`;
    setApiKey(newKey);
  };

  return (
    <div className="container mx-auto max-w-3xl py-6">
      <div className="text-center space-y-2 mb-8">
        <div className="text-sm text-muted-foreground mb-4">GET STARTED WITH LINKT</div>
        <h1 className="text-3xl font-bold tracking-tight mb-4">Set up observability</h1>
        <p className="text-lg text-muted-foreground">
          Trace, debug and monitor your application
        </p>
      </div>

      <div className="space-y-8 max-w-2xl mx-auto">
        <div>
          <div className="flex items-center gap-2 mb-4">
            <span className="text-muted-foreground">1.</span>
            <h3 className="text-lg font-medium">New key created:</h3>
          </div>
          <div className="space-y-4">
            <Button 
              variant="default" 
              className="bg-primary hover:bg-primary/90"
              onClick={generateKey}
            >
              Generate API Key
            </Button>
            {apiKey && (
              <CodeBlock 
                code={apiKey}
                showLineNumbers={false}
              />
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-4">
            <span className="text-muted-foreground">2.</span>
            <h3 className="text-lg font-medium">Install dependencies</h3>
          </div>
          <div>
            <Tabs defaultValue="typescript">
              <TabsList>
                <TabsTrigger value="typescript">
                  TypeScript
                </TabsTrigger>
                <TabsTrigger value="python">
                  Python
                </TabsTrigger>
              </TabsList>
              <TabsContent value="typescript" className="mt-2">
                <CodeBlock 
                  code="npm install @linkt/tracing"
                />
              </TabsContent>
              <TabsContent value="python" className="mt-2">
                <CodeBlock 
                  code="pip install linkt-tracing"
                />
              </TabsContent>
            </Tabs>
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-4">
            <span className="text-muted-foreground">3.</span>
            <h3 className="text-lg font-medium">Configure environment</h3>
          </div>
          <div className="mb-2 text-muted-foreground text-left">Project Name</div>
          <Input
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            className="mb-4 bg-muted font-mono"
          />
          <CodeBlock 
            code={`LINKT_TRACING_V2=true
LINKT_ENDPOINT="https://api.linkt.dev"
LINKT_API_KEY="${apiKey || '<your-api-key>'}"
LINKT_PROJECT="${projectName}"`}
          />
        </div>

        <div>
          <div className="flex items-center gap-2 mb-4">
            <span className="text-muted-foreground">4.</span>
            <h3 className="text-lg font-medium">Run any LLM, Chat model, or Chain. Its trace will be sent to this project.</h3>
          </div>
          <CodeBlock 
            code={`from linkt_tracing import ChatModel

llm = ChatModel()
llm.invoke("Hello, world!")`}
          />
        </div>
      </div>
    </div>
  );
};

export default SetupTracing; 