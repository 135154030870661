import React, { useState, useEffect } from 'react';
import { useUser, useClerk } from '@clerk/clerk-react';
import { Sparkles, LayoutDashboard, Settings as SettingsIcon, GitBranch, FolderKanban, Terminal } from 'lucide-react';

// UI Components
import { Avatar, AvatarFallback } from './ui/avatar';

// Custom Components
import FineTuning from './FineTuning';
import Dashboard from './Dashboard';
import Onboarding from './Onboarding';
import Settings from './Settings';
import Tracing from './Tracing';
import Projects from './Projects';
import Playground from './Playground';

// Utils
import { cn } from '../lib/utils';
import { supabase } from '../lib/supabase';

const getGradient = (str) => {
  const gradients = [
    'from-indigo-500 via-purple-500 to-pink-500',
    'from-rose-400 via-fuchsia-500 to-indigo-500',
    'from-blue-500 via-teal-500 to-emerald-500',
    'from-yellow-400 via-orange-500 to-pink-500',
    'from-green-400 via-cyan-500 to-blue-500',
    'from-purple-500 via-violet-500 to-indigo-500'
  ];
  
  const index = str?.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % gradients.length;
  return gradients[index];
};

const AppLayout = () => {
  const { user } = useUser();
  const { signOut } = useClerk();
  const [selectedView, setSelectedView] = useState('dashboard');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userPreferences, setUserPreferences] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserPreferences = async () => {
    try {
      const { data, error } = await supabase
        .from('user_preferences')
        .select('*')
        .eq('user_id', `user_${user.id}`)
        .maybeSingle();

      if (error && error.code !== 'PGRST116') throw error;
      setUserPreferences(data || null);
    } catch (error) {
      console.error('Error fetching user preferences:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserPreferences();
    }
  }, [user]);

  const handleAvatarClick = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleOnboardingComplete = () => {
    if (user) {
      setIsLoading(true);
      fetchUserPreferences();
    }
  };

  if (isLoading) {
    return <div className="h-screen flex items-center justify-center">Loading...</div>;
  }

  return (
    <>
      {!userPreferences && (
        <Onboarding onComplete={handleOnboardingComplete} />
      )}
      <div className="h-screen overflow-hidden">
        {/* Left Drawer */}
        <div className="fixed inset-y-0 left-0 w-64 border-r bg-card">
          <div className="flex h-14 items-center justify-between border-b px-4">
            <div className="flex items-center">
              <img src="/favicon.ico" alt="Platform Logo" className="h-8 w-8" />
            </div>
            <div className="avatar-dropdown" style={{ position: 'relative' }}>
              <Avatar className="h-8 w-8 cursor-pointer" onClick={handleAvatarClick}>
                <AvatarFallback className={`bg-gradient-to-br ${getGradient(user?.emailAddresses?.[0]?.emailAddress)} text-white`}>
                  {user?.firstName?.[0] || user?.emailAddresses?.[0]?.emailAddress?.[0] || '?'}
                </AvatarFallback>
              </Avatar>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <button
                    onClick={handleSignOut}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    Sign Out
                  </button>
                </div>
              )}
            </div>
          </div>
          <nav className="space-y-1 p-2">
            <button
              className={cn(
                "w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors",
                selectedView === 'dashboard' ? "bg-primary/10 text-primary" : "hover:bg-primary/5"
              )}
              onClick={() => setSelectedView('dashboard')}
            >
              <LayoutDashboard className="h-4 w-4" />
              Dashboard
            </button>
            <button
              className={cn(
                "w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors",
                selectedView === 'projects' ? "bg-primary/10 text-primary" : "hover:bg-primary/5"
              )}
              onClick={() => setSelectedView('projects')}
            >
              <FolderKanban className="h-4 w-4" />
              Projects
            </button>
            <button
              className={cn(
                "w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors",
                selectedView === 'tracing' ? "bg-primary/10 text-primary" : "hover:bg-primary/5"
              )}
              onClick={() => setSelectedView('tracing')}
            >
              <GitBranch className="h-4 w-4" />
              Tracing
            </button>
            <button
              className={cn(
                "w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors",
                selectedView === 'playground' ? "bg-primary/10 text-primary" : "hover:bg-primary/5"
              )}
              onClick={() => setSelectedView('playground')}
            >
              <Terminal className="h-4 w-4" />
              Playground
            </button>
            <button
              className={cn(
                "w-full flex items-center gap-3 rounded-lg px-3 py-2 text-sm transition-colors",
                selectedView === 'settings' ? "bg-primary/10 text-primary" : "hover:bg-primary/5"
              )}
              onClick={() => setSelectedView('settings')}
            >
              <SettingsIcon className="h-4 w-4" />
              Settings
            </button>
          </nav>
        </div>

        {/* Main Content */}
        <div className="pl-64 pt-14 h-screen overflow-auto">
          <main className="container py-6">
            {selectedView === 'dashboard' && <Dashboard companyName={userPreferences?.company_name} />}
            {selectedView === 'playground' && <Playground />}
            {selectedView === 'projects' && <Projects />}
            {selectedView === 'tracing' && <Tracing />}
            {selectedView === 'settings' && <Settings />}
          </main>
        </div>
      </div>
    </>
  );
};

export default AppLayout;