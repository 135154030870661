import React, { useState, useEffect } from 'react';
import { Card, CardContent } from './ui/card';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer 
} from 'recharts';
import { Clock, DollarSign, GitBranch, MessageSquare } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '@clerk/clerk-react';
import { Button } from './ui/button';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import SetupTracing from './SetupTracing';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-lg border bg-background p-2 shadow-sm">
        <div className="grid grid-cols-2 gap-2">
          <div className="font-medium">{label}</div>
          <div className="font-mono text-right">
            {payload[0].value.toFixed(payload[0].name === 'cost' ? 4 : 2)}
            {payload[0].name === 'cost' && '$'}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const MetricCard = ({ title, icon: Icon, chart, value, trend }) => (
  <Card>
    <CardContent className="p-4">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <div className="p-2 rounded-md bg-primary/10">
            <Icon className="h-4 w-4 text-primary" />
          </div>
          <h3 className="font-medium">{title}</h3>
        </div>
        <div className="text-2xl font-bold">
          {typeof value === 'number' ? `${value.toFixed(2)}${title.includes('Latency') ? 's' : ''}` : value}
        </div>
      </div>
      <div className="h-[180px] w-full">
        {chart}
      </div>
    </CardContent>
  </Card>
);

const Dashboard = ({ companyName }) => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get JWT token from Clerk
        const token = await getToken();
        if (!token) {
          throw new Error('No authentication token available');
        }

        // Set Supabase auth
        supabase.auth.setSession({
          access_token: token,
          refresh_token: ''
        });

        // Get traces for the last 7 days (to match Tracing component)
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const { data: traces, error } = await supabase
          .from('traces')
          .select('*')
          .eq('user_id', user?.id)
          .eq('project_id', 'playground')
          .gte('start_time', sevenDaysAgo.toISOString())
          .order('start_time', { ascending: true });

        if (error) {
          console.error('Supabase query error:', error);
          throw error;
        }

        console.log('Raw traces:', traces);

        // Process traces into daily metrics
        const dailyMetrics = {};
        traces.forEach(trace => {
          const date = new Date(trace.start_time).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          
          if (!dailyMetrics[date]) {
            dailyMetrics[date] = {
              date,
              latency: 0,
              cost: 0,
              traces: 0,
              tokens: 0,
              totalLatency: 0
            };
          }

          // Make sure we're accessing the correct fields from the trace
          const latency = trace.latency || 0;
          const cost = trace.cost || 0;
          const tokens = trace.total_tokens || 0;

          dailyMetrics[date].traces += 1;
          dailyMetrics[date].totalLatency += latency;
          dailyMetrics[date].cost += cost;
          dailyMetrics[date].tokens += tokens;
        });

        console.log('Daily metrics:', dailyMetrics);

        // Fill in missing dates with zero values
        const allDates = [];
        for (let i = 0; i < 7; i++) {  // Changed from 30 to 7 days
          const date = new Date();
          date.setDate(date.getDate() - i);
          const dateStr = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          if (!dailyMetrics[dateStr]) {
            dailyMetrics[dateStr] = {
              date: dateStr,
              latency: 0,
              cost: 0,
              traces: 0,
              tokens: 0,
              totalLatency: 0
            };
          }
          allDates.push(dateStr);
        }

        // Calculate averages and format data
        const formattedData = allDates
          .sort((a, b) => new Date(a) - new Date(b))
          .map(date => {
            const day = dailyMetrics[date];
            return {
              date: day.date,
              latency: day.traces > 0 ? day.totalLatency / day.traces : 0,
              cost: day.cost,
              traces: day.traces,
              tokens: day.traces > 0 ? Math.round(day.tokens / day.traces) : 0
            };
          });

        console.log('Formatted data:', formattedData);
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching trace data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchData();
    }
  }, [user, getToken]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-lg text-muted-foreground">Loading metrics...</div>
      </div>
    );
  }

  const latestMetrics = data.length > 0 ? data[data.length - 1] : { latency: 0, cost: 0, traces: 0, tokens: 0 };

  return (
    <div className="-mt-[15px]">
      <div className="mb-2 flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold tracking-tight">Dashboard</h1>
          <p className="text-sm text-muted-foreground">
            Monitor your model performance and usage metrics
          </p>
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="outline" size="sm">
              <GitBranch className="h-4 w-4 mr-2" />
              Setup Tracing
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
            <SetupTracing />
          </DialogContent>
        </Dialog>
      </div>

      <div className="grid grid-cols-2 gap-3">
        {/* Latency Chart */}
        <MetricCard
          title="Average Latency"
          icon={Clock}
          value={latestMetrics.latency}
          chart={
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--border))" vertical={false} />
                <XAxis 
                  dataKey="date" 
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => `${value.toFixed(2)}s`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="latency"
                  stroke="hsl(var(--primary))"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          }
        />

        {/* Cost Chart */}
        <MetricCard
          title="Cost per Trace"
          icon={DollarSign}
          value={`$${latestMetrics.cost.toFixed(4)}`}
          chart={
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--border))" vertical={false} />
                <XAxis 
                  dataKey="date" 
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={(value) => `$${value.toFixed(4)}`}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="cost"
                  stroke="hsl(var(--primary))"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          }
        />

        {/* Traces Chart */}
        <MetricCard
          title="Number of Traces"
          icon={GitBranch}
          value={latestMetrics.traces}
          chart={
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--border))" vertical={false} />
                <XAxis 
                  dataKey="date" 
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="traces"
                  stroke="hsl(var(--primary))"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          }
        />

        {/* Tokens Chart */}
        <MetricCard
          title="Tokens per Trace"
          icon={MessageSquare}
          value={latestMetrics.tokens}
          chart={
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="hsl(var(--border))" vertical={false} />
                <XAxis 
                  dataKey="date" 
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  stroke="hsl(var(--muted-foreground))"
                  fontSize={12}
                  tickLine={false}
                  axisLine={false}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="tokens"
                  stroke="hsl(var(--primary))"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          }
        />
      </div>
    </div>
  );
};

export default Dashboard; 