import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { 
  MessageSquare,
  BarChart3,
  ChevronRight,
  MessageCircle,
  CircleUser,
  Bot as BotIcon,
  CheckCircle2,
  Search,
  ArrowUpDown,
  Pencil,
  RotateCcw,
  Redo2,
  Bold,
  Italic,
  List,
  ListOrdered,
  Quote,
  Table2,
  Code2,
  X,
  Printer,
  HelpCircle,
  GripVertical,
  AlertTriangle,
  XCircle,
  Trash2
} from 'lucide-react';
import { cn } from '../lib/utils';
import { Badge } from './ui/badge';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from './ui/select';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './ui/dialog';

const ModelCard = ({ title, description, icon: Icon, onClick }) => (
  <Card 
    className="relative overflow-hidden transition-all hover:bg-accent/50 cursor-pointer group"
    onClick={onClick}
  >
    <CardContent className="flex flex-col items-center justify-center text-center p-12 space-y-4">
      <div className="rounded-full bg-primary/10 p-4 mb-2">
        <Icon className="h-8 w-8 text-primary" />
      </div>
      <div>
        <h3 className="text-2xl font-medium mb-2">{title}</h3>
        <p className="text-muted-foreground text-base">{description}</p>
      </div>
    </CardContent>
  </Card>
);

const AnnotationTool = ({ response, onFinish }) => {
  const [labels, setLabels] = useState([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [selection, setSelection] = useState({ text: '', start: 0, end: 0 });
  const [showLabelMenu, setShowLabelMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showContextInput, setShowContextInput] = useState(false);
  const [selectedLabelType, setSelectedLabelType] = useState(null);
  const [contextInput, setContextInput] = useState('');
  const [showClearDialog, setShowClearDialog] = useState(false);

  const labelTypes = [
    { 
      id: 'helpful', 
      name: 'Helpful Answer', 
      description: 'Response directly addresses the question',
      contextPrompt: 'Why was this particularly helpful?',
      icon: CheckCircle2,
      color: 'bg-emerald-50 text-emerald-700 border-emerald-200 hover:bg-emerald-100' 
    },
    { 
      id: 'aboveAndBeyond', 
      name: 'Above and Beyond', 
      description: 'Provides additional valuable context',
      contextPrompt: 'What makes this go above and beyond?',
      icon: BarChart3,
      color: 'bg-blue-50 text-blue-700 border-blue-200 hover:bg-blue-100' 
    },
    { 
      id: 'misleading', 
      name: 'Misleading', 
      description: 'Contains incorrect implications',
      contextPrompt: 'What makes this misleading?',
      icon: AlertTriangle,
      color: 'bg-amber-50 text-amber-700 border-amber-200 hover:bg-amber-100' 
    },
    { 
      id: 'inaccurate', 
      name: 'Inaccurate', 
      description: 'Contains factual errors',
      contextPrompt: 'What is inaccurate about this?',
      icon: XCircle,
      color: 'bg-red-50 text-red-700 border-red-200 hover:bg-red-100' 
    }
  ];

  const getTextNodesAndOffsets = (container) => {
    const textNodes = [];
    const walk = document.createTreeWalker(container, NodeFilter.SHOW_TEXT, null, false);
    let node;
    let offset = 0;

    while (node = walk.nextNode()) {
      const length = node.textContent.length;
      textNodes.push({
        node,
        start: offset,
        end: offset + length
      });
      offset += length;
    }

    return textNodes;
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    if (!selection.toString()) {
      setShowLabelMenu(false);
      return;
    }

    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    
    // Get the container element
    const container = document.querySelector('[data-annotation-container]');
    if (!container) return;

    // Get all text nodes and their offsets
    const textNodes = getTextNodesAndOffsets(container);
    
    // Find start position
    let startOffset = 0;
    for (const { node, start } of textNodes) {
      if (node === range.startContainer) {
        startOffset = start + range.startOffset;
        break;
      }
    }

    // Set selection with correct offsets
    setSelection({
      text: selection.toString(),
      start: startOffset,
      end: startOffset + selection.toString().length
    });
    
    setMenuPosition({
      x: rect.left + (rect.width / 2),
      y: rect.top - 10
    });
    
    setShowLabelMenu(true);
  };

  const handleLabelSelect = (labelType) => {
    setSelectedLabelType(labelTypes.find(t => t.id === labelType));
    setShowLabelMenu(false);
    setShowContextInput(true);
  };

  const handleContextSubmit = () => {
    if (!selection.text) return;

    // Check if we're at the annotation limit
    if (labels.length >= 5) {
      // Optionally show a message to the user
      alert("Maximum of 5 annotations reached");
      return;
    }

    // Check for overlapping annotations
    const isOverlapping = labels.some(label => {
      return (
        (selection.start >= label.start && selection.start <= label.end) ||
        (selection.end >= label.start && selection.end <= label.end) ||
        (selection.start <= label.start && selection.end >= label.end)
      );
    });

    if (isOverlapping) {
      alert("Annotations cannot overlap");
      return;
    }

    setLabels([
      ...labels,
      {
        text: selection.text,
        start: selection.start,
        end: selection.end,
        type: selectedLabelType.id,
        context: contextInput
      }
    ]);

    setShowContextInput(false);
    setContextInput('');
    setSelectedLabelType(null);
    window.getSelection().removeAllRanges();
  };

  const getHighlightedText = () => {
    // First, create a temporary element to parse the response HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = response;

    // Get all text nodes and their positions
    const textNodes = getTextNodesAndOffsets(tempDiv);
    
    // Sort labels by start position
    const sortedLabels = [...labels].sort((a, b) => a.start - b.start);

    // Apply highlights
    let result = response;
    let offset = 0;

    sortedLabels.forEach(label => {
      const labelType = labelTypes.find(t => t.id === label.type);
      const before = result.slice(0, label.start + offset);
      const highlighted = result.slice(label.start + offset, label.end + offset);
      const after = result.slice(label.end + offset);

      const highlightSpan = `
        <span 
          class="${labelType.color} px-1 rounded-md group relative cursor-help" 
          title="${label.context || 'No additional context provided'}"
          data-annotation-id="${label.start}-${label.end}"
          data-annotation-type="${label.type}"
        >
          ${highlighted}
          ${label.context ? `
            <div class="absolute hidden group-hover:block bottom-full left-0 mb-2 w-64 p-2 rounded-lg shadow-lg ${labelType.color} border-2 border-current">
              <p class="text-xs">${label.context}</p>
            </div>
          ` : ''}
        </span>
      `;
      
      result = before + highlightSpan + after;
      offset += highlightSpan.length - highlighted.length;
    });

    return result;
  };

  const handleClearAll = () => {
    setLabels([]);
    setShowClearDialog(false);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-start gap-4 p-6 rounded-lg bg-muted/50">
        <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center">
          <HelpCircle className="h-6 w-6 text-primary" />
        </div>
        <div className="flex-1">
          <h3 className="text-lg font-medium">Annotate the Response</h3>
          <p className="text-muted-foreground">Select text and choose a label to annotate parts of the response.</p>
        </div>
      </div>

      <Card>
        <CardContent className="p-6 space-y-6">
          {/* Label Types Grid */}
          <div className="grid grid-cols-2 gap-4">
            {labelTypes
              .filter(type => type.name.toLowerCase().includes(filterQuery.toLowerCase()))
              .map(type => {
                const Icon = type.icon;
                return (
                  <div
                    key={type.id}
                    className={cn(
                      "p-4 rounded-lg border transition-colors",
                      type.color
                    )}
                  >
                    <div className="flex items-center gap-3">
                      <Icon className="h-5 w-5" />
                      <div>
                        <div className="font-medium">{type.name}</div>
                        <div className="text-sm opacity-80">{type.description}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          {/* Text Content */}
          <Card>
            <CardContent className="p-4">
              <div 
                data-annotation-container
                className="relative font-mono text-sm leading-relaxed"
                onMouseUp={handleTextSelection}
                dangerouslySetInnerHTML={{ __html: getHighlightedText() }}
              />
            </CardContent>
          </Card>

          {/* Annotation Counter */}
          <div className="flex justify-between items-center text-sm text-muted-foreground">
            <div>
              {labels.length} of 5 annotations used
            </div>
            {labels.length > 0 && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowClearDialog(true)}
              >
                <Trash2 className="h-4 w-4 mr-2" />
                Clear All
              </Button>
            )}
          </div>

          {/* Clear All Dialog */}
          <Dialog open={showClearDialog} onOpenChange={setShowClearDialog}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Clear All Annotations</DialogTitle>
                <DialogDescription>
                  Are you sure you want to clear all annotations? This action cannot be undone.
                </DialogDescription>
              </DialogHeader>
              <DialogFooter className="flex gap-2 justify-end">
                <Button
                  variant="outline"
                  onClick={() => setShowClearDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleClearAll}
                >
                  Clear All
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>

          {/* Label Menu Popup */}
          {showLabelMenu && (
            <div
              className="fixed z-50 bg-background border rounded-lg shadow-lg py-1 min-w-[200px] animate-in fade-in slide-in-from-top-2 duration-200"
              style={{
                left: `${menuPosition.x}px`,
                top: `${menuPosition.y}px`,
                transform: 'translate(-50%, -100%)'
              }}
            >
              {labelTypes.map(type => {
                const Icon = type.icon;
                return (
                  <button
                    key={type.id}
                    className={cn(
                      "w-full px-4 py-2.5 text-sm text-left hover:bg-muted flex items-center gap-2 group transition-colors",
                      type.color.replace('bg-', 'hover:bg-').replace('50', '100')
                    )}
                    onClick={() => handleLabelSelect(type.id)}
                  >
                    <Icon className="h-4 w-4" />
                    <span className="font-medium">{type.name}</span>
                  </button>
                );
              })}
            </div>
          )}

          {/* Context Input Dialog */}
          {showContextInput && selectedLabelType && (
            <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
              <Card className="w-full max-w-lg mx-4 animate-in fade-in zoom-in duration-200">
                <CardHeader>
                  <div className="flex items-center gap-3">
                    <div className={cn(
                      "p-2 rounded-lg",
                      selectedLabelType.color
                    )}>
                      {selectedLabelType.icon && <selectedLabelType.icon className="h-5 w-5" />}
                    </div>
                    <div>
                      <CardTitle>Add Context (Optional)</CardTitle>
                      <CardDescription>{selectedLabelType.contextPrompt}</CardDescription>
                    </div>
                  </div>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="p-3 rounded-lg bg-muted/50">
                    <p className="text-sm font-mono">"{selection.text}"</p>
                  </div>
                  <Textarea
                    placeholder="Add your explanation here..."
                    value={contextInput}
                    onChange={(e) => setContextInput(e.target.value)}
                    className="min-h-[100px]"
                  />
                </CardContent>
                <div className="flex justify-end gap-2 p-4 border-t">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setShowContextInput(false);
                      setContextInput('');
                      setSelectedLabelType(null);
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    onClick={handleContextSubmit}
                    className={cn(
                      "text-white",
                      selectedLabelType.id === 'helpful' && "bg-emerald-600 hover:bg-emerald-700",
                      selectedLabelType.id === 'aboveAndBeyond' && "bg-blue-600 hover:bg-blue-700",
                      selectedLabelType.id === 'misleading' && "bg-amber-600 hover:bg-amber-700",
                      selectedLabelType.id === 'inaccurate' && "bg-red-600 hover:bg-red-700"
                    )}
                  >
                    Add Context
                  </Button>
                </div>
              </Card>
            </div>
          )}
        </CardContent>
      </Card>

      <div className="flex justify-end">
        <Button onClick={onFinish} size="lg">
          Submit Annotations
          <ChevronRight className="h-4 w-4 ml-2" />
        </Button>
      </div>
    </div>
  );
};

const ChatInterface = ({ method, projectData, projectType, onResponseSelect, chatHistory, setChatHistory }) => {
  const [userInput, setUserInput] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamedText1, setStreamedText1] = useState('');
  const [streamedText2, setStreamedText2] = useState('');
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedResponse, setEditedResponse] = useState('');

  const response1 = "Here's a Python solution to sort a list from smallest to largest:\n\n```python\nmy_list = [3, 1, 4, 1, 5, 9, 2, 6, 5, 3]\nsorted_list = sorted(my_list)\nprint(sorted_list)  # Output: [1, 1, 2, 3, 3, 4, 5, 5, 6, 9]\n```\n\nThe `sorted()` function returns a new sorted list, leaving the original list unchanged.";
  const response2 = "To sort a list from smallest to largest in Python, you can use the built-in `sort()` method:\n\n```python\nmy_list = [5, 2, 9, 1, 7]\nmy_list.sort()\nprint(my_list)  # Output: [1, 2, 5, 7, 9]\n```\n\nThis modifies the list in-place, which is more memory efficient.";

  const simulateStreaming = async () => {
    setIsStreaming(true);
    let text1 = '';
    let text2 = '';
    
    // Stream both responses simultaneously
    const maxLength = Math.max(response1.length, response2.length);
    for (let i = 0; i < maxLength; i++) {
      if (i < response1.length) {
        text1 += response1[i];
        setStreamedText1(text1);
      }
      if (i < response2.length) {
        text2 += response2[i];
        setStreamedText2(text2);
      }
      await new Promise(resolve => setTimeout(resolve, 10));
    }
    
    setIsStreaming(false);
  };

  const handleSubmit = () => {
    if (!userInput.trim()) return;
    setIsSubmitted(true);
    simulateStreaming();

    // Add to chat history
    if (setChatHistory) {
      setChatHistory(prev => [...prev, {
        type: 'user',
        content: userInput
      }]);
    }
  };

  const handleResponseSelect = (id) => {
    if (isStreaming) return;
    setSelectedResponse(id);

    if (method === 'rlhf') {
      // For RLHF, pass the selected response to parent for annotation
      onResponseSelect?.(id === 1 ? streamedText1 : streamedText2);
    } else {
      // For SFT, proceed with editing
      setEditedResponse(id === 1 ? streamedText1 : streamedText2);
      setIsEditing(true);
    }

    // Add to chat history
    if (setChatHistory) {
      setChatHistory(prev => [...prev, {
        type: 'assistant',
        content: id === 1 ? streamedText1 : streamedText2
      }]);
    }
  };

  const handleFinishEditing = () => {
    setIsEditing(false);
    setIsSubmitted(false);
    setUserInput('');
    setSelectedResponse(null);
    setStreamedText1('');
    setStreamedText2('');
  };

  const renderEditingInterface = () => {
    if (!isEditing) return null;

    if (method === 'rlhf') {
      return (
        <AnnotationTool
          response={selectedResponse === 1 ? streamedText1 : streamedText2}
          onFinish={handleFinishEditing}
        />
      );
    }

    return (
      <div className="space-y-4">
        <div className="flex items-start gap-3 p-4 rounded-lg bg-muted/50">
          <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
            <Pencil className="h-5 w-5 text-primary" />
          </div>
          <div className="flex-1">
            <h3 className="font-medium">Refine your response</h3>
            <p className="text-sm text-muted-foreground">Edit the response to make it more appropriate for the prompt.</p>
          </div>
        </div>

        <Card>
          <CardContent className="p-4 space-y-4">
            {/* Editor Toolbar */}
            <div className="flex items-center gap-2 border-b pb-2">
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <RotateCcw className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Redo2 className="h-4 w-4" />
              </Button>
              <div className="w-px h-4 bg-border mx-2" />
              <Button variant="ghost" size="sm" className="h-8 px-3">
                H1
              </Button>
              <Button variant="ghost" size="sm" className="h-8 px-3">
                H2
              </Button>
              <Button variant="ghost" size="sm" className="h-8 px-3">
                H3
              </Button>
              <div className="w-px h-4 bg-border mx-2" />
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Bold className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Italic className="h-4 w-4" />
              </Button>
              <div className="w-px h-4 bg-border mx-2" />
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <List className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <ListOrdered className="h-4 w-4" />
              </Button>
              <div className="w-px h-4 bg-border mx-2" />
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Quote className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Table2 className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Code2 className="h-4 w-4" />
              </Button>
              <div className="flex-1" />
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <X className="h-4 w-4" />
              </Button>
              <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                <Printer className="h-4 w-4" />
              </Button>
            </div>

            {/* Code Editor */}
            <div className="relative">
              <div className="absolute right-2 top-2 flex items-center gap-2">
                <Select defaultValue="python">
                  <SelectTrigger className="h-8 text-xs">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="python">python</SelectItem>
                    <SelectItem value="javascript">javascript</SelectItem>
                    <SelectItem value="typescript">typescript</SelectItem>
                  </SelectContent>
                </Select>
                <Button variant="ghost" size="sm" className="h-8">
                  Copy
                </Button>
              </div>
              <Textarea
                value={editedResponse}
                onChange={(e) => setEditedResponse(e.target.value)}
                className="min-h-[300px] font-mono bg-muted/50 resize-none p-4"
              />
            </div>
          </CardContent>
        </Card>

        <div className="flex justify-end">
          <Button onClick={handleFinishEditing}>
            Finish Editing
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8">
      <Card className="border-none shadow-none bg-muted/50">
        <CardContent className="space-y-2 p-4">
          <div className="flex items-center gap-3">
            <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
              <MessageCircle className="h-5 w-5 text-primary" />
            </div>
            <div>
              <h3 className="font-medium">Start a Chat</h3>
              <p className="text-sm text-muted-foreground">Write a prompt to start a chat session with the model.</p>
            </div>
          </div>
          <div className="space-y-4">
            <Textarea
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Write a prompt to start a chat session with the model."
              className="mt-2 bg-background"
            />
            <div className="flex justify-end">
              <Button onClick={handleSubmit} disabled={!userInput.trim() || isSubmitted}>
                Submit
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* User Message */}
      {isSubmitted && (
        <div className="flex items-start gap-3 p-4 rounded-lg bg-muted/50">
          <div className="h-10 w-10 rounded-lg bg-muted flex items-center justify-center">
            <CircleUser className="h-5 w-5 text-muted-foreground" />
          </div>
          <div className="flex-1">
            <p className="text-sm">{userInput}</p>
          </div>
        </div>
      )}

      {/* Response Selection Section */}
      {isSubmitted && (
        <div className="space-y-4">
          <div className="flex items-start gap-3 p-4 rounded-lg bg-muted/50">
            <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
              <BotIcon className="h-5 w-5 text-primary" />
            </div>
            <div className="flex-1">
              <h3 className="font-medium">Select the best response</h3>
              <p className="text-sm text-muted-foreground">When the responses are fully loaded, select the best response from the options.</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Card
              className={cn(
                "transition-all",
                selectedResponse === 1 && "border-primary bg-primary/5",
                isStreaming ? "opacity-70" : "hover:bg-accent/50 cursor-pointer"
              )}
              onClick={() => !isStreaming && handleResponseSelect(1)}
            >
              <CardContent className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-medium">Response 1</h3>
                  {selectedResponse === 1 && (
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                  )}
                </div>
                <div className="text-sm text-muted-foreground whitespace-pre-wrap font-mono">
                  {streamedText1 || (
                    <div className="h-20 flex items-center justify-center">
                      <div className="text-primary animate-pulse">Generating response...</div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>

            <Card
              className={cn(
                "transition-all",
                selectedResponse === 2 && "border-primary bg-primary/5",
                isStreaming ? "opacity-70" : "hover:bg-accent/50 cursor-pointer"
              )}
              onClick={() => !isStreaming && handleResponseSelect(2)}
            >
              <CardContent className="p-4">
                <div className="flex justify-between items-start mb-2">
                  <h3 className="font-medium">Response 2</h3>
                  {selectedResponse === 2 && (
                    <CheckCircle2 className="h-5 w-5 text-primary" />
                  )}
                </div>
                <div className="text-sm text-muted-foreground whitespace-pre-wrap font-mono">
                  {streamedText2 || (
                    <div className="h-20 flex items-center justify-center">
                      <div className="text-primary animate-pulse">Generating response...</div>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Editing Interface */}
          {renderEditingInterface()}

          {!isEditing && (
            <div className="flex justify-end">
              <Button
                disabled={!selectedResponse || isStreaming}
                onClick={() => handleResponseSelect(selectedResponse)}
              >
                Continue
                <ChevronRight className="h-4 w-4 ml-2" />
              </Button>
            </div>
          )}
        </div>
      )}

      {/* Info text at bottom */}
      {selectedResponse && !isStreaming && !isEditing && (
        <div className="flex items-center gap-2 text-sm text-muted-foreground">
          <div className="h-1 w-1 rounded-full bg-muted-foreground" />
          Click to select the best response and continue
        </div>
      )}
    </div>
  );
};

const CandidateCard = ({ candidate, index, provided }) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    className="p-4 rounded-lg border bg-background shadow-sm mb-4 transition-all hover:bg-accent/50 relative group"
  >
    <div className="flex items-center gap-4">
      <div {...provided.dragHandleProps} className="cursor-grab hover:bg-accent p-2 rounded">
        <GripVertical className="h-5 w-5 text-muted-foreground" />
      </div>
      
      <div className="flex-1">
        <div className="flex items-center gap-2 mb-2">
          <div className="w-8 h-8 bg-primary/10 rounded-full flex items-center justify-center text-lg font-semibold text-primary">
            {index + 1}
          </div>
          <h3 className="font-medium text-lg">
            {candidate.name}
          </h3>
          <Badge variant="outline">
            Match Score: {(candidate.score * 100).toFixed(0)}%
          </Badge>
        </div>
        <p className="text-muted-foreground">
          {candidate.text}
        </p>
        <div className="flex gap-2 mt-3">
          {candidate.tags.map((tag, tagIndex) => (
            <Badge 
              key={tagIndex} 
              variant="secondary"
            >
              {tag}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const RankingInterface = ({ projectType }) => {
  const [query, setQuery] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [completedRankings, setCompletedRankings] = useState(0);
  
  // Multiple sets of candidates to cycle through
  const candidateSets = [
    [
      {
        id: 1,
        name: "Sarah Chen",
        text: "Senior Software Engineer with 8 years of experience in full-stack development. Expert in React, Node.js, and cloud architecture. Led teams at Google and Amazon, delivering high-impact projects.",
        score: 0.95,
        tags: ["Senior Level", "Full-stack", "Leadership", "Cloud"]
      },
      {
        id: 2,
        name: "Michael Rodriguez",
        text: "Software Engineer with 5 years of experience in web development. Proficient in JavaScript and React. Contributed to multiple successful projects at startups. Good team player with experience in agile environments.",
        score: 0.82,
        tags: ["Mid Level", "Frontend", "Startup", "Agile"]
      },
      {
        id: 3,
        name: "Emily Zhang",
        text: "Machine Learning Engineer with 6 years of experience. Specialized in NLP and LLM fine-tuning. Published research on transformer architectures. Strong background in Python and PyTorch.",
        score: 0.88,
        tags: ["ML/AI", "Research", "NLP", "Python"]
      },
      {
        id: 4,
        name: "David Kumar",
        text: "Backend Engineer with 4 years of experience in distributed systems. Expert in Go and Rust. Built high-performance microservices handling millions of requests. Strong focus on system design.",
        score: 0.79,
        tags: ["Backend", "Systems", "Go", "Rust"]
      }
    ],
    [
      {
        id: 5,
        name: "Alex Thompson",
        text: "DevOps Engineer with 7 years of experience. Expert in AWS, Kubernetes, and CI/CD pipelines. Implemented infrastructure as code at scale. Strong focus on automation and reliability.",
        score: 0.91,
        tags: ["DevOps", "Cloud", "Infrastructure", "Automation"]
      },
      {
        id: 6,
        name: "Lisa Wang",
        text: "UI/UX Designer with 6 years of experience. Created intuitive interfaces for enterprise applications. Expert in Figma and design systems. Strong focus on accessibility.",
        score: 0.87,
        tags: ["Design", "UI/UX", "Frontend", "Accessibility"]
      },
      {
        id: 7,
        name: "James Foster",
        text: "Data Engineer with 5 years of experience. Built scalable data pipelines and warehouses. Expert in Python, SQL, and Apache Spark. Strong background in ETL processes.",
        score: 0.84,
        tags: ["Data", "ETL", "Python", "SQL"]
      },
      {
        id: 8,
        name: "Nina Patel",
        text: "Security Engineer with 4 years of experience. Implemented zero-trust architecture and threat detection systems. Expert in penetration testing and security audits.",
        score: 0.81,
        tags: ["Security", "Infrastructure", "Compliance", "Testing"]
      }
    ],
    [
      {
        id: 9,
        name: "Marcus Johnson",
        text: "Mobile Developer with 6 years of experience. Built cross-platform applications using React Native and Flutter. Expert in native iOS and Android development.",
        score: 0.89,
        tags: ["Mobile", "React Native", "iOS", "Android"]
      },
      {
        id: 10,
        name: "Sophie Anderson",
        text: "Product Manager with 8 years of experience. Led development of successful SaaS products. Strong background in agile methodologies and user research.",
        score: 0.93,
        tags: ["Product", "Leadership", "Agile", "Strategy"]
      },
      {
        id: 11,
        name: "Ray Martinez",
        text: "QA Engineer with 5 years of experience. Implemented automated testing frameworks. Expert in performance testing and continuous integration.",
        score: 0.85,
        tags: ["QA", "Testing", "Automation", "CI/CD"]
      },
      {
        id: 12,
        name: "Hannah Kim",
        text: "Technical Lead with 7 years of experience. Architected scalable microservices. Expert in system design and team mentorship.",
        score: 0.90,
        tags: ["Leadership", "Architecture", "Mentorship", "Systems"]
      }
    ]
  ];

  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const [candidates, setCandidates] = useState(candidateSets[0]);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    const items = Array.from(candidates);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setCandidates(items);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setShowSuccess(true);

    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Show success state briefly
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    setShowSuccess(false);
    setIsSubmitting(false);
    setCompletedRankings(prev => prev + 1);

    // Move to next set or reset to first set if we're at the end
    if (currentSetIndex < candidateSets.length - 1) {
      setCurrentSetIndex(prev => prev + 1);
      setCandidates(candidateSets[currentSetIndex + 1]);
    } else {
      setCurrentSetIndex(0);
      setCandidates(candidateSets[0]);
    }
  };

  return (
    <div className="space-y-8">
      <Card>
        <CardHeader>
          <div className="flex items-center gap-4">
            <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
              <Search className="h-5 w-5 text-primary" />
            </div>
            <div>
              <CardTitle>Enter Job Search Query</CardTitle>
              <CardDescription>Write a search query to find relevant candidates.</CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <Input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Looking for a senior software engineer with React experience..."
            className="mb-4"
          />
          <div className="flex gap-2">
            {["Software Engineering", "Senior Level", "React", "Full-stack"].map((tag) => (
              <Badge 
                key={tag}
                variant="secondary"
              >
                {tag}
              </Badge>
            ))}
          </div>
        </CardContent>
      </Card>

      {candidates.length > 0 && (
        <Card>
          <CardHeader>
            <div className="flex items-center gap-4">
              <div className="h-10 w-10 rounded-lg bg-primary/10 flex items-center justify-center">
                <ArrowUpDown className="h-5 w-5 text-primary" />
              </div>
              <div>
                <CardTitle>Rank candidates by relevance</CardTitle>
                <CardDescription>Use the grip handle to drag and reorder candidates from most relevant to least relevant.</CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="candidates">
                {(provided) => (
                  <div 
                    {...provided.droppableProps} 
                    ref={provided.innerRef}
                    className={cn(
                      "transition-all duration-500",
                      showSuccess && "opacity-50 pointer-events-none"
                    )}
                  >
                    {candidates.map((candidate, index) => (
                      <Draggable 
                        key={candidate.id} 
                        draggableId={candidate.id.toString()} 
                        index={index}
                      >
                        {(provided) => (
                          <CandidateCard 
                            candidate={candidate}
                            index={index}
                            provided={provided}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            
            {showSuccess && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="flex items-center gap-2 text-primary animate-in fade-in zoom-in duration-300">
                  <CheckCircle2 className="h-6 w-6" />
                  <span className="text-lg font-medium">Ranking Submitted!</span>
                </div>
              </div>
            )}
            
            <div className="flex justify-between mt-4">
              <div className="text-sm text-muted-foreground">
                Drag to reorder • Most relevant at the top
              </div>
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <span className="animate-pulse">Submitting...</span>
                  </>
                ) : (
                  <>
                    Submit Ranking
                    <ChevronRight className="h-4 w-4 ml-2" />
                  </>
                )}
              </Button>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const RLHFInterface = ({ projectData, projectType }) => {
  const [step, setStep] = useState('chat');
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  const handleResponseSelect = (response) => {
    setSelectedResponse(response);
    setStep('annotation');
  };

  if (step === 'annotation') {
    return (
      <div className="space-y-6">
        <AnnotationTool 
          response={selectedResponse}
          onFinish={() => {
            setStep('chat');
            setSelectedResponse(null);
          }}
        />
      </div>
    );
  }

  return (
    <ChatInterface 
      method="rlhf"
      projectData={projectData}
      projectType={projectType}
      onResponseSelect={handleResponseSelect}
      chatHistory={chatHistory}
      setChatHistory={setChatHistory}
    />
  );
};

const FineTuning = ({ initialType, projectData, onBack }) => {
  const [currentView, setCurrentView] = useState(null);

  useEffect(() => {
    // Set the view based on both method and subtype
    if (projectData?.subtype === 'ranking') {
      setCurrentView('ranking');
    } else if (initialType === 'rlhf') {
      setCurrentView('rlhf');
    } else if (initialType === 'sft') {
      setCurrentView('chat');
    }
  }, [initialType, projectData]);

  // Render the appropriate view based on the project type
  const renderView = () => {
    switch (currentView) {
      case 'ranking':
        return (
          <RankingInterface 
            projectData={projectData}
            projectType="Ranking"
          />
        );

      case 'rlhf':
        return (
          <RLHFInterface 
            projectData={projectData}
            projectType="RLHF"
          />
        );

      case 'chat':
        return (
          <ChatInterface 
            method="sft"
            projectData={projectData}
            projectType="Chat"
          />
        );

      default:
        return (
          <div className="text-center py-8 text-muted-foreground">
            Loading...
          </div>
        );
    }
  };

  return (
    <div>
      {renderView()}
    </div>
  );
};

// Custom icons to match the design
const ChatIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M12 3C6.5 3 2 6.5 2 11C2 13.4 3.1 15.5 4.8 17L2.5 21L7 19.5C8.5 20.3 10.2 20.7 12 20.7C17.5 20.7 22 17.2 22 12.7C22 8.2 17.5 3 12 3Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RerankIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3 7H21M3 12H21M3 17H21"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default FineTuning; 