import React, { useState, useEffect } from 'react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Badge } from './ui/badge';
import { ScrollArea } from './ui/scroll-area';
import { Textarea } from './ui/textarea';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from './ui/select';
import { Switch } from './ui/switch';
import { Separator } from './ui/separator';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { 
  Plus,
  Settings,
  Copy,
  Trash,
  Play,
  RefreshCw,
  Link,
  MessageSquare,
  Timer,
  Database,
  DollarSign,
  ChevronDown,
  Bot,
  User,
  Save,
  Loader2
} from 'lucide-react';
import { cn } from '../lib/utils';
import { toast } from 'sonner';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from './ui/dialog';
import { RadioGroup, RadioGroupItem } from './ui/radio-group';
import { Label } from './ui/label';
import { Alert, AlertDescription } from './ui/alert';
import { createClient } from '@supabase/supabase-js';
import { useUser } from '@clerk/clerk-react';
import { useAuth } from '@clerk/clerk-react';

const OPENAI_COST_PER_TOKEN = 0.00003; // Approximate cost per token for GPT-4

const Message = ({ type, content, onEdit, onDelete }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content);
      toast.success('Copied to clipboard');
    } catch (error) {
      toast.error('Failed to copy to clipboard');
    }
  };

  return (
    <div className="group relative space-y-2">
      <div className="flex items-center gap-2 text-sm">
        <Badge variant="outline" className="font-mono uppercase">
          {type}
        </Badge>
        <div className="flex-1" />
        <div className="flex items-center gap-1 text-muted-foreground">
          <Button variant="ghost" size="icon" className="h-8 w-8" onClick={copyToClipboard}>
            <Copy className="h-4 w-4" />
          </Button>
          {onDelete && (
            <Button variant="ghost" size="icon" className="h-8 w-8" onClick={onDelete}>
              <Trash className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
      <Textarea 
        value={content}
        onChange={(e) => onEdit?.(e.target.value)}
        className="min-h-[100px] font-mono text-sm"
      />
    </div>
  );
};

const MarkdownContent = ({ content }) => {
  return (
    <ReactMarkdown
      className="prose prose-sm dark:prose-invert max-w-none"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeSanitize]}
      components={{
        // Style code blocks
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline ? (
            <pre className={cn(
              "relative rounded-lg p-4 bg-muted/50",
              className
            )}>
              <div className="absolute top-3 right-3 flex items-center gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-6 w-6 hover:bg-muted"
                  onClick={() => {
                    navigator.clipboard.writeText(children);
                    toast.success('Code copied to clipboard');
                  }}
                >
                  <Copy className="h-3 w-3" />
                </Button>
              </div>
              <code className={cn(
                "text-sm font-mono",
                className
              )} {...props}>
                {children}
              </code>
            </pre>
          ) : (
            <code className="px-1 py-0.5 rounded-md bg-muted font-mono text-sm" {...props}>
              {children}
            </code>
          );
        },
        // Style links
        a({ node, className, children, ...props }) {
          return (
            <a
              className="text-primary underline underline-offset-4 hover:text-primary/80"
              target="_blank"
              rel="noopener noreferrer"
              {...props}
            >
              {children}
            </a>
          );
        },
        // Style tables
        table({ node, className, children, ...props }) {
          return (
            <div className="my-4 w-full overflow-auto">
              <table className="w-full border-collapse border border-border" {...props}>
                {children}
              </table>
            </div>
          );
        },
        th({ node, className, children, ...props }) {
          return (
            <th
              className="border border-border bg-muted px-4 py-2 text-left font-medium"
              {...props}
            >
              {children}
            </th>
          );
        },
        td({ node, className, children, ...props }) {
          return (
            <td
              className="border border-border px-4 py-2"
              {...props}
            >
              {children}
            </td>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
};

const SavePromptDialog = ({ open, onOpenChange, onSave, selectedPrompt }) => {
  const [saveType, setSaveType] = useState('new');
  const [name, setName] = useState('');

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Save Prompt to Prompt Hub</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <RadioGroup value={saveType} onValueChange={setSaveType} className="grid gap-4">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="new" id="new" />
              <Label htmlFor="new">Save as new prompt</Label>
            </div>
            {selectedPrompt && (
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="existing" id="existing" />
                <Label htmlFor="existing">Save to "{selectedPrompt.name}"</Label>
              </div>
            )}
          </RadioGroup>
          {saveType === 'new' && (
            <div className="grid gap-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter prompt name"
              />
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button onClick={() => {
            onSave(saveType === 'new' ? { type: 'new', name } : { type: 'existing' });
            onOpenChange(false);
          }}>
            Next
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const LoadPromptDialog = ({ open, onOpenChange, savedPrompts, onLoad }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Load Prompt from Prompt Hub</DialogTitle>
        </DialogHeader>
        <Alert variant="warning" className="my-4">
          <AlertDescription>
            Loading a prompt will override any current changes to the prompt template and variables
          </AlertDescription>
        </Alert>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label>Prompt Name</Label>
            <Select value={selectedPrompt} onValueChange={setSelectedPrompt}>
              <SelectTrigger>
                <SelectValue placeholder="Select prompt..." />
              </SelectTrigger>
              <SelectContent>
                {savedPrompts.map(prompt => (
                  <SelectItem key={prompt.id} value={prompt.id}>
                    {prompt.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button 
            onClick={() => {
              onLoad(selectedPrompt);
              onOpenChange(false);
            }}
            disabled={!selectedPrompt}
          >
            Load Prompt
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const Playground = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [selectedModel, setSelectedModel] = useState('gpt-4o');
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [promptName, setPromptName] = useState('');
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [showLoadDialog, setShowLoadDialog] = useState(false);
  const [messages, setMessages] = useState([
    { type: 'system', content: 'You are a helpful AI assistant.' },
    { type: 'human', content: '{question}' }
  ]);
  const [inputs, setInputs] = useState({
    question: 'What can you help me with?'
  });
  const [output, setOutput] = useState(null);
  const [streamingContent, setStreamingContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fancyRendering, setFancyRendering] = useState(true);
  const [error, setError] = useState(null);
  const [traceId, setTraceId] = useState(null);
  const [supabaseClient, setSupabaseClient] = useState(null);

  // Initialize Supabase client once
  const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY
  );

  // Load saved prompts on component mount
  useEffect(() => {
    const saved = localStorage.getItem('savedPrompts');
    if (saved) {
      setSavedPrompts(JSON.parse(saved));
    }
  }, []);

  // Save prompt to localStorage
  const handleSavePrompt = (saveDetails) => {
    if (saveDetails.type === 'new') {
      if (!saveDetails.name.trim()) {
        toast.error('Please enter a name for your prompt');
        return;
      }

      const newPrompt = {
        id: Date.now().toString(),
        name: saveDetails.name,
        messages: messages,
        inputs: inputs
      };

      const updatedPrompts = [...savedPrompts, newPrompt];
      setSavedPrompts(updatedPrompts);
      localStorage.setItem('savedPrompts', JSON.stringify(updatedPrompts));
      setSelectedPrompt(newPrompt);
      toast.success('Prompt saved successfully');
    } else {
      // Update existing prompt
      const updatedPrompts = savedPrompts.map(p => 
        p.id === selectedPrompt.id 
          ? { ...p, messages, inputs }
          : p
      );
      setSavedPrompts(updatedPrompts);
      localStorage.setItem('savedPrompts', JSON.stringify(updatedPrompts));
      toast.success('Prompt updated successfully');
    }
  };

  // Load a saved prompt
  const loadSavedPrompt = (id) => {
    const prompt = savedPrompts.find(p => p.id === id);
    if (prompt) {
      setMessages(prompt.messages);
      setInputs(prompt.inputs);
      setSelectedPrompt(prompt);
      toast.success('Prompt loaded successfully');
    }
  };

  // Delete a saved prompt
  const deleteSavedPrompt = (e, id) => {
    e.stopPropagation();
    const updatedPrompts = savedPrompts.filter(p => p.id !== id);
    setSavedPrompts(updatedPrompts);
    localStorage.setItem('savedPrompts', JSON.stringify(updatedPrompts));
    if (selectedPrompt?.id === id) {
      setSelectedPrompt(null);
    }
    toast.success('Prompt deleted successfully');
  };

  const updateMessage = (index, content) => {
    const newMessages = [...messages];
    newMessages[index] = { ...newMessages[index], content };
    setMessages(newMessages);
  };

  const deleteMessage = (index) => {
    if (messages.length <= 2) {
      toast.error("Can't delete required messages");
      return;
    }
    const newMessages = messages.filter((_, i) => i !== index);
    setMessages(newMessages);
  };

  const addMessage = () => {
    setMessages([...messages, { type: 'human', content: '' }]);
  };

  const processTemplate = (template, variables) => {
    let processed = template;
    Object.entries(variables).forEach(([key, value]) => {
      processed = processed.replace(`{${key}}`, value);
    });
    return processed;
  };

  const handleStart = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setStreamingContent('');
      setOutput(null);
      const startTime = Date.now();

      // Process messages with variables
      const processedMessages = messages.map(msg => ({
        role: msg.type === 'system' ? 'system' : msg.type === 'human' ? 'user' : 'assistant',
        content: processTemplate(msg.content, inputs)
      }));

      // Create trace in Supabase
      const newTraceId = crypto.randomUUID();
      setTraceId(newTraceId);
      
      const { error: insertError } = await supabase
        .from('traces')
        .insert({
          id: newTraceId,
          user_id: user?.id,
          project_id: 'playground',
          name: 'RunnableSequence',
          input: JSON.stringify(processedMessages),
          model: selectedModel,
          type: 'sequence',
          start_time: new Date().toISOString(),
          metadata: {
            messages: processedMessages,
            inputs
          }
        });

      if (insertError) {
        console.error('Insert error:', insertError);
        throw new Error(insertError.message);
      }

      // Make streaming API call
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Accept': 'text/event-stream',
        },
        body: JSON.stringify({
          model: selectedModel,
          messages: processedMessages,
          temperature: 0.7,
          stream: true
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error?.message || 'Failed to get response from OpenAI');
      }

      // Process the stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let totalTokens = 0;
      let accumulatedContent = '';
      let firstTokenTime = null;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode the chunk and add it to the buffer
        buffer += decoder.decode(value);

        // Process complete messages from the buffer
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') continue;

            try {
              const json = JSON.parse(data);
              const content = json.choices[0]?.delta?.content || '';
              if (content) {
                if (!firstTokenTime) {
                  firstTokenTime = Date.now();
                }
                totalTokens++;
                accumulatedContent += content;
                setStreamingContent(accumulatedContent);
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      // Calculate final metrics
      const endTime = Date.now();
      const duration = (endTime - startTime) / 1000;
      const timeToFirstToken = firstTokenTime ? (firstTokenTime - startTime) : null;
      const estimatedCost = totalTokens * OPENAI_COST_PER_TOKEN;

      // Save the final output
      setOutput({
        content: accumulatedContent,
        metadata: {
          duration: duration.toFixed(2) + 's',
          status: 'SUCCESS',
          tokens: totalTokens,
          cost: '$' + estimatedCost.toFixed(3),
          timestamp: 'just now'
        }
      });

      // Update trace in Supabase
      const { error: updateError } = await supabase
        .from('traces')
        .update({
          output: accumulatedContent,
          end_time: new Date().toISOString(),
          latency: duration,
          time_to_first_token: timeToFirstToken,
          total_tokens: totalTokens,
          cost: estimatedCost,
          status: 'success'
        })
        .eq('id', newTraceId);

      if (updateError) throw updateError;

    } catch (err) {
      console.error('Playground error:', err);
      setError(err.message);
      toast.error(err.message);

      // Update trace with error in Supabase
      if (traceId && supabaseClient) {
        await supabaseClient
          .from('traces')
          .update({
            error: err.message,
            end_time: new Date().toISOString(),
            status: 'error'
          })
          .eq('id', traceId);
      }
    } finally {
      setIsLoading(false);
      setStreamingContent('');
      setSupabaseClient(null);
    }
  };

  // Get current prompt name
  const getCurrentPromptName = () => {
    if (!selectedPrompt) {
      return 'Custom Prompt';
    }
    return selectedPrompt.name || 'Custom Prompt';
  };

  return (
    <div className="-mt-[15px]">
      <div className="mb-2">
        <h1 className="text-2xl font-bold tracking-tight">Playground</h1>
        <p className="text-sm text-muted-foreground">
          Test and experiment with your model in real-time
        </p>
      </div>

      {/* Header */}
      <div className="mb-8 flex items-center gap-4">
        <div className="flex items-center gap-4">
          <Button variant="outline" className="gap-2" onClick={() => setShowLoadDialog(true)}>
            Load Prompt
          </Button>
          <Badge variant="secondary" className="text-sm">
            {getCurrentPromptName()}
          </Badge>
        </div>

        <Select 
          value={selectedModel} 
          onValueChange={setSelectedModel} 
          disabled
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select model" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="gpt-4o">gpt-4o</SelectItem>
          </SelectContent>
        </Select>

        <Button variant="outline" size="icon">
          <Settings className="h-4 w-4" />
        </Button>

        <div className="flex-1" />

        <Button variant="outline" className="gap-2" onClick={() => setShowSaveDialog(true)}>
          <Save className="h-4 w-4" />
          Save Prompt
        </Button>

        <Button 
          className="gap-2" 
          onClick={handleStart}
          disabled={isLoading}
        >
          {isLoading ? (
            <Loader2 className="h-4 w-4 animate-spin" />
          ) : (
            <Play className="h-4 w-4" />
          )}
          {isLoading ? 'Running...' : 'Start'}
        </Button>
      </div>

      {/* Save/Load Dialogs */}
      <SavePromptDialog
        open={showSaveDialog}
        onOpenChange={setShowSaveDialog}
        onSave={handleSavePrompt}
        selectedPrompt={selectedPrompt}
      />
      <LoadPromptDialog
        open={showLoadDialog}
        onOpenChange={setShowLoadDialog}
        savedPrompts={savedPrompts}
        onLoad={(promptId) => {
          const prompt = savedPrompts.find(p => p.id === promptId);
          setSelectedPrompt(prompt);
          loadSavedPrompt(promptId);
        }}
      />

      {/* Main Content */}
      <div className="grid grid-cols-2 gap-8">
        {/* Left Side - Prompts */}
        <div className="space-y-6">
          <Card>
            <CardContent className="p-6 space-y-6">
              {messages.map((message, index) => (
                <Message 
                  key={index}
                  type={message.type}
                  content={message.content}
                  onEdit={(content) => updateMessage(index, content)}
                  onDelete={index > 1 ? () => deleteMessage(index) : undefined}
                />
              ))}
              <Button variant="outline" className="w-full gap-2" onClick={addMessage}>
                <Plus className="h-4 w-4" />
                Add Message
              </Button>
            </CardContent>
          </Card>
        </div>

        {/* Right Side - Input/Output */}
        <div className="space-y-6">
          {/* Inputs */}
          <Card>
            <CardContent className="p-6">
              <h2 className="text-lg font-semibold mb-4">Inputs</h2>
              <div className="space-y-4">
                {Object.entries(inputs).map(([key, value]) => (
                  <div key={key} className="space-y-2">
                    <div className="flex items-center gap-2">
                      <label className="text-sm font-medium">{key}</label>
                      <Button variant="ghost" size="icon" className="h-6 w-6">
                        <Link className="h-3 w-3" />
                      </Button>
                    </div>
                    <Input 
                      value={value} 
                      onChange={(e) => setInputs(prev => ({ ...prev, [key]: e.target.value }))}
                    />
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          {/* Output */}
          <Card>
            <CardContent className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-lg font-semibold">Output</h2>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-muted-foreground">Fancy rendering</span>
                  <Switch checked={fancyRendering} onCheckedChange={setFancyRendering} />
                </div>
              </div>

              {error ? (
                <div className="rounded-lg border border-destructive/50 bg-destructive/10 p-4 text-sm text-destructive">
                  {error}
                </div>
              ) : (isLoading || output) ? (
                <div className="space-y-4">
                  <div className="flex gap-4 items-start">
                    <div className="w-8 h-8 rounded-full bg-primary/10 flex items-center justify-center mt-1">
                      <Bot className="h-4 w-4 text-primary" />
                    </div>
                    <div className="flex-1">
                      {fancyRendering ? (
                        <MarkdownContent content={isLoading ? streamingContent : output.content} />
                      ) : (
                        <pre className="text-sm font-mono whitespace-pre-wrap">
                          {isLoading ? streamingContent : output.content}
                        </pre>
                      )}
                      {isLoading && (
                        <span className="inline-block w-1 h-4 ml-0.5 -mb-0.5 bg-primary animate-pulse" />
                      )}
                    </div>
                  </div>

                  {output && (
                    <div className="flex items-center gap-4 text-sm text-muted-foreground">
                      <div className="flex items-center gap-1">
                        <Timer className="h-4 w-4" />
                        {output.metadata.duration}
                      </div>
                      <Badge variant="outline" className="font-mono">
                        {output.metadata.status}
                      </Badge>
                      <div className="flex items-center gap-1">
                        <MessageSquare className="h-4 w-4" />
                        {output.metadata.tokens}
                      </div>
                      <div className="flex items-center gap-1">
                        <DollarSign className="h-4 w-4" />
                        {output.metadata.cost}
                      </div>
                      <div className="flex-1" />
                      <span>{output.metadata.timestamp}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center h-32 text-sm text-muted-foreground">
                  Run the playground to see the output
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Playground;
