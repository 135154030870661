import React, { useState, useEffect } from 'react';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { ScrollArea } from './ui/scroll-area';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from './ui/select';
import { Badge } from './ui/badge';
import { cn } from '../lib/utils';
import { supabase } from '../lib/supabase';
import { useUser } from '@clerk/clerk-react';
import { toast } from 'sonner';
import { 
  Plus,
  Search,
  Filter,
  Clock,
  Settings,
  Sparkles,
  Bot,
  BarChart3,
  MessageSquare,
  ChevronRight,
  ArrowUpDown,
  Loader2,
  ArrowLeft
} from 'lucide-react';
import FineTuning from './FineTuning';

const projectTypes = [
  {
    id: 'fine-tuning',
    name: 'Fine-tuning',
    description: 'Create custom models trained on your data',
    icon: Sparkles,
    subtypes: [
      {
        id: 'chat',
        name: 'Chat Model',
        description: 'Train a model for conversational tasks',
        methods: ['sft', 'rlhf']
      },
      {
        id: 'ranking',
        name: 'Ranking Model',
        description: 'Train a model for content ranking and evaluation',
        methods: ['rlhf']
      }
    ]
  }
];

const ProjectCard = ({ project, onClick }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'training': return 'bg-yellow-500/10 text-yellow-500';
      case 'completed': return 'bg-green-500/10 text-green-500';
      case 'failed': return 'bg-red-500/10 text-red-500';
      default: return 'bg-gray-500/10 text-gray-500';
    }
  };

  const getTypeIcon = (type) => {
    switch (type) {
      case 'fine-tuning': return Sparkles;
      default: return Settings;
    }
  };

  const Icon = getTypeIcon(project.type);

  return (
    <Card 
      className="hover:bg-accent/50 cursor-pointer transition-colors"
      onClick={() => onClick(project)}
    >
      <CardContent className="p-8">
        <div className="flex items-start justify-between">
          <div className="space-y-4">
            <div className="flex items-center gap-3">
              <Icon className="h-5 w-5 text-primary" />
              <h3 className="font-medium text-lg">{project.name}</h3>
            </div>
            <p className="text-sm text-muted-foreground">{project.description}</p>
          </div>
          {project.status !== 'created' && (
            <Badge className={cn("ml-2", getStatusColor(project.status))}>
              {project.status}
            </Badge>
          )}
        </div>
        
        <div className="mt-6 space-y-3">
          <div className="flex items-center gap-2 text-sm text-muted-foreground">
            <span className="capitalize">{project.subtype}</span>
            <span>•</span>
            <span className="uppercase">{project.method}</span>
          </div>
          
          {project.status === 'training' && (
            <div className="w-full bg-muted rounded-full h-2">
              <div 
                className="bg-primary h-2 rounded-full transition-all"
                style={{ width: `${project.progress}%` }}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const CreateProjectDialog = ({ isVisible, onClose, onCreateProject }) => {
  const { user } = useUser();
  const [step, setStep] = useState(1);
  const [projectType, setProjectType] = useState(null);
  const [subtype, setSubtype] = useState(null);
  const [method, setMethod] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [description, setDescription] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = async () => {
    try {
      setIsCreating(true);

      const newProject = {
        user_id: `user_${user.id}`,
        name: projectName,
        type: projectType.id,
        subtype,
        method,
        status: 'created',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        progress: 0,
        description,
        dataset: {
          size: 0,
          files: []
        },
        training: {
          epochs: 0,
          current_epoch: 0,
          loss: null,
          metrics: {}
        }
      };

      const { data, error } = await supabase
        .from('projects')
        .insert(newProject)
        .select()
        .single();

      if (error) throw error;

      onCreateProject(data);
      onClose();
      toast.success('Project created successfully');
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Failed to create project');
    } finally {
      setIsCreating(false);
    }
  };

  if (!isVisible) return null;

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <h2 className="text-lg font-medium">Choose Project Type</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {projectTypes.map((type) => (
                <Card 
                  key={type.id}
                  className={cn(
                    "cursor-pointer hover:bg-accent/50 transition-colors",
                    projectType?.id === type.id && "border-primary"
                  )}
                  onClick={() => {
                    setProjectType(type);
                    setStep(2);
                  }}
                >
                  <CardContent className="p-6 flex items-start gap-4">
                    <div className="rounded-lg bg-primary/10 p-2">
                      <type.icon className="h-6 w-6 text-primary" />
                    </div>
                    <div>
                      <h3 className="font-medium mb-1">{type.name}</h3>
                      <p className="text-sm text-muted-foreground">{type.description}</p>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <h2 className="text-lg font-medium">Configure {projectType.name}</h2>
            <div className="space-y-4">
              <div className="space-y-2">
                <label className="text-sm font-medium">Project Name</label>
                <Input
                  placeholder="Enter project name"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                />
              </div>
              
              <div className="space-y-2">
                <label className="text-sm font-medium">Description</label>
                <Textarea
                  placeholder="Describe your project"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="space-y-2">
                <label className="text-sm font-medium">Model Type</label>
                <Select value={subtype} onValueChange={setSubtype}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select model type" />
                  </SelectTrigger>
                  <SelectContent>
                    {projectType.subtypes.map((sub) => (
                      <SelectItem key={sub.id} value={sub.id}>
                        {sub.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              {subtype && (
                <div className="space-y-2">
                  <label className="text-sm font-medium">Training Method</label>
                  <Select value={method} onValueChange={setMethod}>
                    <SelectTrigger>
                      <SelectValue placeholder="Select training method" />
                    </SelectTrigger>
                    <SelectContent>
                      {projectType.subtypes
                        .find(sub => sub.id === subtype)
                        ?.methods.map((m) => (
                          <SelectItem key={m} value={m}>
                            {m.toUpperCase()}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-background/80 backdrop-blur-sm">
      <div className="fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
        <Card className="w-[90vw] max-w-2xl">
          <CardContent className="p-6">
            {renderStep()}
            
            <div className="mt-6 flex justify-end gap-2">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              {step > 1 && (
                <Button variant="outline" onClick={() => setStep(step - 1)}>
                  Back
                </Button>
              )}
              {step === 2 && (
                <Button 
                  disabled={!projectName || !subtype || !method}
                  onClick={handleCreate}
                >
                  Create Project
                </Button>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const Projects = () => {
  const { user } = useUser();
  const [searchQuery, setSearchQuery] = useState('');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showProjectHeader, setShowProjectHeader] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProjects();
  }, [user]);

  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('user_id', `user_${user.id}`)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setProjects(data || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('Failed to load projects');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateProject = (newProject) => {
    setProjects([newProject, ...projects]);
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
    setShowProjectHeader(true);
  };

  if (selectedProject) {
    return (
      <div className="space-y-6">
        {showProjectHeader && (
          <div className="flex items-center justify-between px-6 py-4">
            <div className="flex items-center gap-4">
              <Button variant="ghost" size="sm" onClick={() => {
                setSelectedProject(null);
                setShowProjectHeader(false);
              }} className="gap-1">
                <ArrowLeft className="h-4 w-4" />
                Back
              </Button>
              <div className="flex items-center gap-2">
                <h1 className="text-lg font-medium">{selectedProject.name}</h1>
                <p className="text-sm text-muted-foreground">{selectedProject.description}</p>
              </div>
            </div>
            <Badge variant="outline" className="text-sm">
              {selectedProject.method === 'rlhf' ? 'RLHF' : selectedProject.method === 'sft' ? 'Chat' : 'Ranking'} Fine-tuning
            </Badge>
          </div>
        )}
        
        <FineTuning 
          initialType={selectedProject.method}
          projectData={selectedProject}
          onBack={() => {
            setSelectedProject(null);
            setShowProjectHeader(false);
          }}
        />
      </div>
    );
  }

  return (
    <div className="-mt-[15px]">
      <div className="flex items-center justify-between mb-6">
        <div>
          <h1 className="text-2xl font-bold tracking-tight">Projects</h1>
          <p className="text-sm text-muted-foreground">
            Manage and fine-tune your model projects
          </p>
        </div>
        <Button onClick={() => setShowCreateDialog(true)} className="gap-2">
          <Plus className="h-4 w-4" />
          New Project
        </Button>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-[200px]">
          <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {projects.map((project) => (
            <ProjectCard 
              key={project.id} 
              project={project}
              onClick={handleProjectSelect}
            />
          ))}
          {projects.length === 0 && (
            <div className="col-span-full text-center py-12 text-muted-foreground">
              No projects yet. Click "New Project" to get started.
            </div>
          )}
        </div>
      )}

      <CreateProjectDialog 
        isVisible={showCreateDialog}
        onClose={() => setShowCreateDialog(false)}
        onCreateProject={handleCreateProject}
      />
    </div>
  );
};

export default Projects; 